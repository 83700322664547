import * as React from 'react'
import { Container, Tab, Nav } from 'react-bootstrap'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '~components/Layout'
import SEO from '~components/seo'
import DATA from './constants'
import { withMap } from '~containers/Home/components/Offices/Map/withMap'
import { MAPBOX_CONFIG } from '~containers/Home/components/Offices/constants'
import { Map } from '~containers/Home/components/Offices/Map'
import { useMapContext } from '~containers/Home/components/Offices/Map/context'
import { useMapController } from '~containers/Home/components/Offices/useMapController'
import {
  offices,
  offices_heading,
  offices_wrap,
  offices_tabs,
  offices_link,
  offices_info,
  offices_title,
  offices_content,
  offices_member,
  offices_member_pic,
  offices_member_name,
  offices_member_position,
} from './style.module.scss'

export const Contact = () => {
  const representatives = useStaticQuery(graphql`
    {
      allTeamJson(filter: { slug: { in: ["wansu-lee", "jeounghoon-yoo"] } }) {
        edges {
          node {
            slug
            name
            position
            photo {
              id
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 42, height: 42, quality: 100, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `)

  const { ref } = useMapContext()
  const { onSelectHandler, defaultPlace } = useMapController()

  return (
    <Layout>
      <Container>
        <SEO title="Offices" />
        <div className={offices}>
          <h1 className={offices_heading}>Offices</h1>
          <Tab.Container defaultActiveKey={defaultPlace} onSelect={onSelectHandler}>
            <div className={offices_wrap}>
              <Nav variant="pills" className={offices_tabs}>
                {DATA.map(({ location }) => (
                  <Nav.Item key={location}>
                    <Nav.Link as="button" className={offices_link} eventKey={location}>
                      {location}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
            <Map ref={ref} />
            <Tab.Content>
              {DATA.map(({ location, data, representativeSlug }) => {
                const representative = representatives?.allTeamJson.edges.find(
                  ({ node: { slug } }) => slug === representativeSlug
                )?.node

                return (
                  <Tab.Pane key={location} eventKey={location}>
                    <div className={offices_info}>
                      {data.map(({ title, content }) => {
                        return (
                          <div key={title}>
                            <p className={offices_title}>{title}</p>
                            <div className={offices_content}>
                              {Array.isArray(content) ? content.map((el) => <p key={el}>{el}</p>) : <p>{content}</p>}
                            </div>
                          </div>
                        )
                      })}
                      {representativeSlug && (
                        <div>
                          <p className={offices_title}>Representative</p>
                          <Link to={`/team/${representativeSlug}`} className={offices_member}>
                            <span className={offices_member_pic}>
                              <GatsbyImage
                                image={representative.photo.childImageSharp.gatsbyImageData}
                                alt={representative.name}
                                style={{ display: 'block' }}
                              />
                            </span>
                            <p className={offices_member_name}>{representative.name}</p>
                            <p className={offices_member_position}>{representative.position}</p>
                          </Link>
                        </div>
                      )}
                    </div>
                  </Tab.Pane>
                )
              })}
            </Tab.Content>
          </Tab.Container>
        </div>
      </Container>
    </Layout>
  )
}

export default withMap(MAPBOX_CONFIG)(Contact)
