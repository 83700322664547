const DATA = [
  {
    location: 'Seoul',
    data: [
      {
        title: 'Address',
        content: '(Seocho-dong, Yangjin Bldg.) 3F., 5F., 138, Banpo-daero, Seocho-gu, Seoul',
      },
      {
        title: 'Contacts',
        content: ['Email: liwulaw@liwulaw.com', 'Phone: +82-2-524-9600, 9620', 'Fax: +82-2-524-9696, 9688, 9655'],
      },
      {
        title: 'Subway/Bus',
        content: ['Subway : Seocho Station Gate No. 7', 'Bus : 405, 740, 5413, Seocho Village Bus 13, 21)'],
      },
    ],
    representativeSlug: 'wansu-lee',
  },
  {
    location: 'Hanoi',
    data: [
      {
        title: 'Address',
        content: 'No. 87, 2F, U Kun Zaw Condo, U Kun Zaw Street, Kan Lane, 10th Ward, Hlaing Township, Yangon, Myanmar',
      },
      {
        title: 'Contacts',
        content: ['Email: jhyoo@liwulaw.com', 'Phone: +95-99-7428-2787, +95-99-6345-8330'],
      },
    ],
    representativeSlug: 'jeounghoon-yoo',
  },
  {
    location: 'Yangon',
    data: [],
  },
  {
    location: 'Phnom Penh',
    data: [],
  },
  {
    location: 'Jakarta',
    data: [],
  },
]

export default DATA
