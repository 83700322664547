// extracted by mini-css-extract-plugin
export const offices = "h_Y";
export const offices_heading = "h_Z";
export const offices_wrap = "h_0";
export const offices_tabs = "h_1";
export const offices_link = "h_2";
export const offices_map = "h_3";
export const offices_info = "h_4";
export const offices_title = "h_5";
export const offices_content = "h_6";
export const offices_member = "h_7";
export const offices_member_name = "h_8";
export const offices_member_position = "h_9";
export const offices_member_pic = "h_bb";